import gql from 'graphql-tag';

const estatus_incidenciasTableQuery = gql`
    query estatus_incidencias($whereConditions:EstatusIncidenciasWhereWhereConditions,$numberItems: Int!, $numberPage: Int!, $fieldOrder: String!) {
        estatus_incidencias(where:$whereConditions,first: $numberItems, page: $numberPage, activo: true, orderBy:[{field: $fieldOrder, order: DESC}]) {
            data {
                id,
                nombre,
                valor
            }
            paginatorInfo {
                count
                currentPage
                firstItem
                lastItem
                hasMorePages
                lastPage
                perPage
                total
            }
        }
    }
`

const queries = { estatus_incidenciasTableQuery };

export default queries;
